










































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ExternalLinkIcon } from "vue-feather-icons";
import Loader from "../ui/Loader.vue";

@Component({
  components: {
    Loader,
    ExternalLinkIcon,
  },
})
export default class MaintenanceHistory extends Vue {
  @Prop() id!: string;
  assetsList: any = [];
  isLoading = false;

  mounted() {
    this.fetchMaintenanceListByAsset();
  }
  async fetchMaintenanceListByAsset() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/history/${this.id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status === 200) {
        this.assetsList = await response.json();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }
}
