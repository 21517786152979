












































































import { Component, Vue } from "vue-property-decorator";
import {
  ArrowLeftIcon,
  RefreshCcwIcon,
  TrashIcon,
  EditIcon,
} from "vue-feather-icons";

import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";
import MaintenancePreview from "../components/maintenance-detail/MaintenancePreview.vue";
import MaintenancePhotos from "../components/maintenance-detail/Maintenance-Photos.vue";
import MaintenanceDocuments from "../components/maintenance-detail/Maintenance-Documents.vue";
import MaintenanceHistory from "../components/maintenance-detail/Maintenance-History.vue";
import Modal from "../components/ui/Modal.vue";

@Component({
  components: {
    ArrowLeftIcon,
    RefreshCcwIcon,
    Loader,
    NoData,
    MaintenancePreview,
    MaintenancePhotos,
    MaintenanceDocuments,
    TrashIcon,
    Modal,
    EditIcon,
    MaintenanceHistory,
  },
})
export default class MaintenanceDetail extends Vue {
  isLoading = true;
  showNoData = false;
  noDataType = "Error";
  basicDetailData = "";
  selectedTab = 0;

  mounted() {
    this.fetchAssetWithId();
  }

  reloadData() {
    this.isLoading = true;
    this.showNoData = false;
    this.fetchAssetWithId();
    this.selectedTab = 0;
  }

  onChangeTab(index: number) {
    this.selectedTab = index;
  }

  async fetchAssetWithId() {
    const id = this.$router.currentRoute.params.id;
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
      } else {
        this.basicDetailData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.basicDetailData = "";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }

  showDeleteModal = false;
  onShowDeleteModal() {
    this.showDeleteModal = true;
    this.idToBeDeleted = this.$router.currentRoute.params.id;
  }

  idToBeDeleted = "";
  async onDeleteMaintenance() {
    if (this.idToBeDeleted !== "") {
      try {
        const response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/${this.idToBeDeleted}`,
          {
            method: "DELETE",
            credentials: "include",
          }
        );

        if (response.status === 200) {
          this.$store.commit("showToastMessage", {
            boldText: "Successfully!",
            text: "Maintenance deleted",
            type: "Success",
          });
          this.$router.replace({ path: "/asset-tracker/maintenance" });
        } else {
          this.$store.commit("showToastMessage", {
            boldText: "Failed!",
            text: "Something went wrong",
            type: "Danger",
          });
        }
      } catch (e) {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: e.message,
          type: "Danger",
        });
      } finally {
        this.onHideDeleteModal();
      }
    } else {
      this.onHideDeleteModal();
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: "Invalid id selected",
        type: "Danger",
      });
    }
  }

  onHideDeleteModal() {
    this.showDeleteModal = false;
  }
}
