





























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CalendarIcon,
  StarIcon,
  MapPinIcon,
  DollarSignIcon,
  ToolIcon,
} from "vue-feather-icons";

@Component({
  components: {
    CalendarIcon,
    StarIcon,
    MapPinIcon,
    DollarSignIcon,
    ToolIcon,
  },
})
export default class MaintenancePreview extends Vue {
  @Prop() basicDetailData!: {};
  @Prop() onChangeTab!: Function;

  activeTab = 0;
  tabs = ["Photos", "Documents", "History"];

  onClickTab(index: number) {
    this.activeTab = index;
    this.onChangeTab(index);
  }
}
