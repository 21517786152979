





























































import { Component, Prop, Vue } from "vue-property-decorator";
import { PlusIcon } from "vue-feather-icons";
import PhotoViewer from "../assets-detail/Photo-Viewer.vue";
import Loader from "../ui/Loader.vue";

@Component({
  components: {
    PlusIcon,
    PhotoViewer,
    Loader,
  },
})
export default class MaintenancePhotos extends Vue {
  @Prop() id!: string;

  mounted() {
    this.fetchAllAssetDouments();
  }

  isLoading = false;
  async fetchAllAssetDouments() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/photo/${this.id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.documentList = data.photos.map((x: any) => ({
          id: x.id,
          fileName: x.token,
          pictureSrc: x.url,
        }));
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  $refs!: {
    fileInput: HTMLInputElement;
  };
  onAddDocument() {
    this.$refs.fileInput.click();
  }

  documentList: Array<{
    fileName: string;
    pictureSrc: string;
  }> = [];
  isUploading = false;
  appDocumentUpload(e: any) {
    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        this.uploadDocToServer(e.target.files[0]);
      }
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: "No file selected",
        type: "Danger",
      });
    }
  }

  async uploadDocToServer(doc: any) {
    try {
      this.isUploading = true;
      const formData = new FormData();
      formData.append("id", this.id);
      formData.append("file", doc);
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/photo/${this.id}`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );
      if (response.status == 200) {
        this.fetchAllAssetDouments();
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isUploading = false;
    }
  }

  async onDeleteAssetDocument(id: string) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/photo/${id}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (response.status === 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Successfully!",
          text: "Deleted maintenance photo",
          type: "Success",
        });
        this.fetchAllAssetDouments();
      } else {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: "Something went wrong",
          type: "Danger",
        });
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }
}
